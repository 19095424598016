@import "./inputWrapperVariables";

@mixin imageContainer {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  border: 1px solid var(--input-border);
  border-radius: 3px;
  cursor: pointer;
  height: 90px;
  width: 90px;
}

@mixin loadingImage {
  filter: blur(1px) grayscale(100%);
}

@mixin error {
  @include inputWrapperError;
}