@import "../styles/inputVariables";

.label {
  width: 100%;
}

.input {
  outline: none;
  transition: all 200ms ease;
  width: 100%;

  &:disabled {
    opacity: 0.3;
    transition: all 200ms ease;
  }

  &::placeholder {
    transition: all 200ms ease;
  }

  &:focus {
    transition: all 200ms ease;
  }

  @include input;

  &.error {
    @include inputError;
  }
}