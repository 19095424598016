@import 'mixins';

@mixin literalLabel {
  min-height: 44px;
}

@mixin literalValue {
  color: var(--text-color);
  font-family: var(--main-font-family);
  font-size: var(--input-text-size);
  font-weight: 800;
  padding: 0.75rem 1.25rem 1.25rem;
}
