.plate {
  border: none;
  background: none;
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
  color: var(--userDataPlate--color);
  text-decoration: none;

  &__icon {
    min-width: 2.5rem;
    min-height: 2.5rem;
  }

  &__name {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
