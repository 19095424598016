@import "./inputVariables";

@mixin textarea {
  @include input;

  height: auto;
  line-height: inherit;
  padding: 15px;
}

@mixin textareaError {
  @include inputError;
}