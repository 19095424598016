:root {
  --logout--font-family: var(--main-font-family);
  --logout--font-size: var(--font-md);
  --logout--font-weight: 400;
  --logout--line-height: 1.188rem;
}

button.logout__button {
  font-size: inherit;
  font-weight: inherit;
  padding: 0;
  margin: 18px;
  border: none;
  display: flex;
  flex-direction: row-reverse;
  height: fit-content;
  gap: 18px;
  align-items: center;
  cursor: pointer;
  font-family: var(--menu_item--font-family);
  font-size: var(--logout--font-size);
  font-weight: var(--logout--font-weight);
  line-height: var(--logout--line-height);

  &,
  &:focus,
  &:focus:not(.disable),
  &:hover,
  &:hover:not(.disable) {
    color: inherit;
    background-color: transparent;
  }
}
