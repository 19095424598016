:root {
  --font-xxs: 0.625rem; //10px
  --font-xs: 0.75rem; //12px
  --font-sm: 0.875rem; //14px
  --font-md: 1rem; //16px
  --font-lg: 1.125rem; //18px
  --font-xl: 1.25rem; //20px
  --font-xxl: 1.5rem; //24px

  --main-font-family: Roboto, sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
