@import 'variables';
@import 'mixins';
@import 'fonts';

body {
  font-family: var(--main-font-family);
  margin: 0;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
ol,
ul,
li,
p {
  font-weight: normal;
  margin: 0;
  padding: 0;
}

svg {
  pointer-events: bounding-box;

  & * {
    pointer-events: none;
  }
}

:global {
  .visibleDesktop {
    @include media-breakpoint-only(xs) {
      display: none;
    }

    @include media-breakpoint-only(sm) {
      display: none;
    }
  }

  .visibleMobile {
    @include media-breakpoint-only(lg) {
      display: none;
    }

    @include media-breakpoint-only(md) {
      display: none;
    }
  }
}
