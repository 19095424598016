@import '../styles/inputWrapperVariables';

.wrapper {
  display: flex;
  position:relative;
  flex-direction: column;
  width: 100%;

  @include inputWrapper;

  &.icon .component {
    padding-right: 3rem;
  }
}

.input_icon {
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  margin-right: 0.5rem;
  cursor: pointer;
  z-index: 10;
}

.description {
  @include description;
}

.error_container {
  display: flex;
  margin-top: 8.5px;

  svg {
    width: 24px;
    height: 20px;
  }
}

.error {
  &::first-letter {
    text-transform: uppercase;
  }

  @include inputWrapperError;
}

.errorIcon {
  margin: 0;
}

.errorLiteral {
  padding-left: 0;
}

.align-start {
  align-self: flex-start;
}

.align-center {
  align-self: center;
}

.align-end {
  align-self: flex-end;
}
