@import '../../styles/mixins';

/* Login */
:root {
  --login_background: #F9F8FD;
}

.wrapper {
  overflow: hidden;

  @include media-breakpoint-up(sm) {
    height: 100vh;
  }

  &::before {
    content: ' ';
    background-color: var(--login_background);
    height: 100%;
    width: calc(50% - 600px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.container {
  @include media-breakpoint-up(sm) {
    max-width: 1200px;
    margin: auto;
    display: flex;
    position: relative;
    z-index: 2;
    height: 100%;
  }
}

.column {
  background-color: var(--login_background);
  padding: 24px;

  @include media-breakpoint-up(sm) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    height: 100%;
    position: relative;
  }

  &:last-child {
    background-color: #fff;

    @include media-breakpoint-up(sm) {
      width: 60%;
    }
  }
}

.logo {
  display: flex;
  justify-content: center;

  svg {
    width: 150px;

    @include media-breakpoint-up(sm) {
      width: auto;
    }
  }
}

.inputs {
  width: 350px;
  margin: auto;
  max-width: 100%;
}

.input__password--wrapper {
  background-color: transparent;
  border: none;
}

.buttons {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.8rem;
}

.recoveryButton {
  border: none;
  background: var(--button-secondary-background);
  color: var(--button-secondary-color);
  padding: 0;
  width: auto;
  height: auto;

  &:hover:not(:disabled) {
    color: var(--button-default-background-hover);
    background: var(--button-default-color-hover);
  }
}

.errors {
  height: 2rem;
  color: var(--dark-orange-color);
  text-align: right;
  margin-bottom: 1.25rem;
}
