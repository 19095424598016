@import 'colors';

/* Buttons */
:root {
  --button-default-background: var(--dark-orange-color);
  --button-default-color: white;
  --button-default-radius: 0.5rem;
  --button-big-radius: 0.5rem;
  --button-small-radius: 0.25rem;
  --button-smallest-radius: 0.25rem;
  --button-default-font-size: 1.125rem;
  --button-big-font-size: 18px;
  --button-default-background-hover: var(--orange-color);
  --button-default-color-hover: white;

  --button-secondary-background: transparent;
  --button-secondary-border: 1px solid var(--dark-orange-color);
  --button-secondary-color: var(--dark-orange-color);
  --button-secondary-color-hover: var(--orange-color);
  --button-secondary-border-hover: 1.5px solid var(--orange-color);

  --button-cancel-background: var(--button-secondary-background);
  --button-cancel-border: var(--button-secondary-border);
  --button-cancel-color: var(--button-secondary-color);
  --button-cancel-color-hover: var(--button-secondary-color-hover);
  --button-cancel-border-hover: var(--button-secondary-border-hover);
}

/* Inputs */
:root {
  --input-border: var(--gray_3--color);
  --input-placeholder: var(--gray_2--color);
  --input-focus-border-color: var(--gray_2--color);
  --input-height: 50px;
  --input-text-size: 14px;
  --input-label-text-size: var(--input-text-size);
  --input-label-focused-text-size: 14px;
  --input-label-offset: 6px;
  --input-label-position: calc(var(--input-height) - var(--input-label-offset));
  --input-description-color: var(--input-placeholder);
}

/* Select */
:root {
  --form-select-text-size: 14px;
  --form-select-text-color: var(--gray_1--color);
  --form-select--placeholder-color: var(--gray_2--color);
  --form-select--border-color: var(--gray_3--color);
  --form-select-disabled--border-color: var(--gray_3--color);
  --form-select--icon-color: var(--gray_1--color);
  --form-select-disabled--icon-color: var(--gray_3--color);
  --form-menu-background: var(--main__background);
  --form-select-selected-option-color: var(--orange-color);
  --form-select-hover-option-background: var(--light-gray-color);
  --form-select-selected-option-background: var(--light-gray-color);
  --form-select-default-option-hover-background: var(--light-gray-color);
  --open-menu-color: var(--gray_2--color);
}

/* Paginator */
:root {
  --paginator-active-border: 1px solid var(--orange-color);
  --paginator-active-border-radius: 5px;
  --paginator-active-color: var(--orange-color);
  --paginator-background: var(--light-gray-color);
  --paginator-color: var(--medium-light-gray-color);
  --paginator-border-radius: 5px;
}

/* Login */
:root {
  --login_right_background: var(--light-gray-color);
}

/* Menu */
:root {
  --menu-separator-color: var(--gray_4--color);
}

/* Globals */
:root {
  --error-color: var(--orange-color);
  --separator-line_1: 1px solid var(--gray_4--color);
  --separator-line_2: 2px solid var(--gray_4--color);
}
