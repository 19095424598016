@import 'mixins';

@mixin inputWrapper {
  color: var(--color-primary);
  margin-bottom: 15px;
}

@mixin description {
  color: var(--input-description-color);
  margin-top: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  
  &::first-letter {
    text-transform: uppercase;
  }
}

@mixin inputWrapperError {
  font-family: var(--main-font-family);
  color: var(--error-color);
  font-size: 12px;
  font-weight: 500;
  padding-left: 5px;
  padding-right: 5px;
  line-height: 20px;
}
