/* Color Names */
:root {
  --orange-color: #f27b3b;
  --dark-orange-color: #ec5d10;
  --dark-grey-color: #323232;
  --medium-dark-gray-color: #818181;
  --medium-light-gray-color: #b9b9b9;
  --light-gray-color: #e2e2e2;
  --white-color: white;
  --ohh_my_eyes-blue: #0000ff;
  --forestgreen-color: #228b22;
  --transparent-gray-color: #f9f9f9;

  --gray_1--color: #323232;
  --gray_2--color: #818181;
  --gray_3--color: #b9b9b9;
  --gray_4--color: #e2e2e2;
}

/*
 New colors 27/02/2023
 https://www.figma.com/file/DgODmu0pMy0yCJ1gdilMle/Slingshot?node-id=10%3A270&t=Led67hhJ0REjQZqw-0
*/

:root {
  --primary_1--color: #ec5d10;
  --primary_2--color: #f27b3b;
  --terciary--color: #fff5eb;
}

/* Alerts */
:root {
  --message-default--background-color: #cce5ff;
  --message-default--border-color: #b8daff;
  --message-default--color: #004085;
  --message-success--background-color: #d4edda;
  --message-success--border-color: #c3e6cb;
  --message-success--color: #155724;
  --message-error--background-color: #f8d7da;
  --message-error--border-color: #f5c6cb;
  --message-error--color: #721c24;
}
