@import 'mixins';

@mixin headerRow {
  color: var(--text-color);
  font-size: 12px;
  font-weight: bold;
  margin: 30px 0 20px;
  text-transform: uppercase;
}

@mixin headerRowCell {
}

@mixin sortIcon {
  display: flex;
  margin-left: 5px;
  opacity: 0;
  transition: color 200ms ease, opacity 200ms ease;
}

@mixin sortIconHover {
  color: var(--primary-color);
  transition: color 200ms ease, opacity 200ms ease;
  opacity: 1;
}

@mixin sortIconActive {
  opacity: 1;
}

@mixin rowItem {
  font-family: var(--main-font-family);
  margin-top: 5px;
  padding-left: 15px;
}

@mixin contentRow {
  border-radius: 3px;
  background-color: white;
  color: var(--text-color);
  font-family: var(--main-font-family);
  font-size: 14px;
  font-weight: normal;
  height: 65px;

  &:hover {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  }
}
