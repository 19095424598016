.frame__container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
}

.frame__content_nomal {

}

.frame__content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;

  width: 400px;
  border-radius: 1em;
  box-shadow: 0px 4px 12px var(--medium-light-gray-color);

  font-family: 'Roboto';
  font-style: normal;
}

.frame__content_normal {
  background: var(--white-color);
  color: black;

  .frame__header_icon {
    color: var(--orange-color);
  }
}

.frame__content_success {
  background-color: var(--orange-color);
  color: white;
}

.frame__header {  
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: left;

  display: flex;
  justify-content: space-between;
  gap: 1em;
}

.frame__header_title {
  flex: 1;
  align-self: center;
  text-align: center;
}

.frame__header_icon {
  width: 34px;
  height: 34px;
}

.frame__header_close {
  width: 12px;
  height: 12px;

  cursor: pointer;

  transition: .25s;

  &:hover {
    opacity: .5;
  }
}

.frame__body {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.frame__footer {
  display: flex;

  button { flex: 1 }
}
