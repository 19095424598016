:root {
  --footer__background-color: white;
  --footer__separator: 1px solid rgba(0, 0, 0, 0.1);
}

.wrapper {
  width: 100%;
  max-height: 3.75rem;
  font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  border-top: var(--footer__separator);
  padding: 0.9375rem 0.9375rem;
  display: flex;
  position: sticky;
  bottom: 0;
  align-items: center;
  column-gap: 3.125rem;
  background-color: var(--footer__background-color);
}

button + button {
  margin-left: 0.625rem;
}

.footer__middle-container {
  flex-grow: 1;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}

footer {
  position: fixed;
  bottom: 0;
}
