@import "../styles/inputPickerVariables";

.datePickerLabel {
  :global {
    .react-datepicker-wrapper,
    .react-datepicker__input-container {
      width: 100%;
    }

    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
      border-bottom-color: var(--dark-orange-color);
    }

    .react-datepicker {
      font-family: 'Roboto';
      letter-spacing: 0.3px;
      border-radius: 20px;
      box-shadow: 5px 5px 5px var(--gray_3--color);

      &__header {
        text-transform: capitalize;
        background-color: var(--dark-orange-color);

        &:not(.react-datepicker__header--has-time-select) {
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
        }
      }

      &__current-month {
        padding: .5rem 0;
      }

      &__current-month, &__day-name {
        color: var(--white-color);
      }
        
      &__day {
        border-radius: 4px;
        box-shadow: 1px 1px 5px lightgray;

        &--keyboard-selected,
        &--selected {
          background-color: var(--dark-orange-color);
          color: var(--white-color) !important;
          box-shadow: 2px 2px 5px var(--dark-orange-color) !important;

          &:hover {
            background-color: var(--dark-orange-color);
            opacity: .5;
          }
        }
      }

      &__day--disabled, &__day--outside-month {
        box-shadow: none;
      }

      &__day--outside-month{
        &:not(.react-datepicker__day--disabled) {
          color: var(--gray_1--color);
        }
      }

      &__close-icon {        
        &::after {
          width: 28px;
          height: 28px;
          background-color: transparent;
          color: var(--medium-light-gray-color);
          font-size: 24px;
          transition: 150ms;
        }

        &:hover::after {
          color: var(--medium-dark-gray-color);
        }
      }
    }
  }
}

.pickerContainer {
  position: relative;
  width: 100%;
}

.dateInput {
  outline: none;
  width: 100%;

  @include dateInput;

  &::placeholder {
    font-style: normal;
  }
}

.pickerIcon {
  cursor: pointer;
  display: flex;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 0;

  @include pickerIcon;
}

.error {
  @include dateInputError;
}