:root {
  --menu_item__selected--color: var(--dark-orange-color);
  --menu_item--color: inherit;
  --menu_item--font-family: var(--main-font-family);
  --menu_item--font-size: var(--font-md);
  --menu_item--font-weight: 400;
  --menu_item__selected--font-weight: 600;
  --menu_item--line-height: 1.188rem;
  --submenu_item--font-family: var(--main-font-family);
  --submenu_item--font-size: var(--font-sm);
  --submenu_item--font-weight: 400;
  --submenu_item--line-height: 1.188rem;
  --submenu_bullet--color: var(--gray_4--color);
  --menu__extended--color: var(--gray_1--color);
  --menu__extended-icon--rotate: 270deg;
  --menu__colapsed-icon--rotate: 0;
}

.menu {
  display: flex;
  margin: 0;

  &.vertical {
    flex-direction: column;
    margin-top: 1.5625rem;

    & .menu__item {
      color: var(--menu_item--color);
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0.313rem 0.938rem;
      gap: 0.625rem;
      flex: none;
      order: 1;
      flex-grow: 1;

      width: 100%;
      height: auto;
      border: none;
      user-select: none;
      background: none;
      cursor: pointer;
      text-decoration: none;
      color: inherit;
      font-family: var(--menu_item--font-family);
      font-size: var(--menu_item--font-size);
      font-weight: var(--menu_item--font-weight);
      line-height: var(--menu_item--line-height);

      &.menu__item--selected {
        color: var(--menu_item__selected--color);
        font-weight: var(--menu_item__selected--font-weight);
        pointer-events: none;

        & ~ .subMenu__item {
          display: flex;

          & > .menu__icon {
            width: 2rem;
            height: 2rem;
          }
        }

        &[data-toggle='true'] {
          & ~ .subMenu__item {
            display: none;
          }

          & > .menu__extend {
            rotate: var(--menu__colapsed-icon--rotate);
          }
        }

        & > .menu__extend {
          rotate: var(--menu__extended-icon--rotate);
        }
      }

      &[data-toggle='true'] {
        & ~ .subMenu__item {
          display: flex;
        }

        & > .menu__extend {
          rotate: var(--menu__extended-icon--rotate);
        }
      }

      &.subMenu__item {
        display: none;
        padding-left: 3.125rem;
        font-family: var(--submenu_item--font-family);
        font-size: var(--submenu_item--font-size);
        font-weight: var(--submenu_item--font-weight);
        line-height: var(--submenu_item--line-height);

        &.submenu__item--selected {
          &::before {
            background-color: var(--menu_item__selected--color);
          }
        }

        &::before {
          content: '';
          padding: 0.25rem;
          background-color: var(--submenu_bullet--color);
          border-radius: 50%;
          display: inline-block;
          margin-right: 5px;
        }
      }

      & > .menu__text {
      }

      & > .menu__icon {
        width: 2.5rem;
        height: 2.5rem;
      }

      & > .menu__extend {
        margin: auto;
        pointer-events: bounding-box;
        color: var(--menu__extended--color);
        rotate: var(--menu__colapsed-icon--rotate);

        & > * {
          pointer-events: none;
        }
      }
    }
  }

  &.onlyIcons {
    & .menu__text {
      display: none !important;
    }

    & .subMenu__item {
      display: none !important;
    }

    & .menu__extend {
      display: none !important;
    }
  }
}
