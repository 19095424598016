.label {
  color: var(--gray_1--color);
  font-size: var(--input-text-size);
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.3px;

  margin-bottom: 8px;
  transition: color 200ms ease;
  // text-transform: capitalize;

  &.disabled {
    color: var(--gray_2--color);
  }
}

.literalLabel {
  color: var(--label-color);
  font-size: 14px;
  margin-bottom: 10px;
}
