@import 'mixins';

@mixin input {
  background: var(--input-backgound);
  border-color: var(--input-border);
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  border-radius: 8px;
  color: var(--text-color);
  font-family: var(--main-font-family);
  font-size: var(--input-text-size);
  font-weight: 500;
  height: var(--input-height);
  line-height: 20px;
  padding: 20px;
  letter-spacing: 0.3px;
  transition: all 200ms ease;

  &::placeholder {
    color: var(--input-placeholder);
  }

  &:not(:read-only):focus {
    color: var(--text-color);
    outline-width: 2px;
    outline-style: solid;
    outline-color: var(--input-focus-border-color);
  }

  &:read-only {
    color: var(--gray_2--color);
    cursor: auto;
  }

  &:disabled {
    border-color: var(--form-select--border-color);
    cursor: auto;
    opacity: 0.3;
  }
}

@mixin inputError {
  border-color: var(--error-color);
  color: var(--error-color);
}

@mixin inputErrorMessage {
  color: var(--error-color);
  font-size: 14px;
  margin-left: 15px;
  margin-top: 5px;
}
