@import "../styles/textareaVariables";

.textarea {
  outline: none;
  transition: all 200ms ease;
  width: 100%;
  resize: none;

  @include textarea;

  &.fullHeightArea {
    flex: 1;
  }

  &.error {
    @include textareaError;
  }
}

.fullHeightLabel {
  @include media-breakpoint-only(xs) {
    flex: 1;
    flex-direction: column;
    display: flex;
  }
}
