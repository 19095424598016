@import 'mixins';

@mixin paginatorContainer {
}

@mixin paginator {
  color: var(--text-color);
  font-size: 14px;
  padding: 0;
}

@mixin page {
  border-radius: 100px;
  margin-right: 10px;
  height: 30px;
  width: 30px;
  background: var(--paginator-background);
  color: var(--paginator-color);
  border-radius: var(--paginator-border-radius);
}

@mixin hoverPage {
  background: var(--paginator-hover-background);
}

@mixin activePage {
  background: var(--paginator-active-background);
  color: var(--paginator-active-color);
  border: var(--paginator-active-border);
  border-radius: var(--paginator-active-border-radius);
}

@mixin resultsData {
  font-size: 14px;
}
