:root {
  --header__separator: var(--separator-line_1);
}

.wrapper {
  border-bottom: var(--header__separator);
  padding: 0.5rem 0.9375rem;
  display: flex;
  position: sticky;
  top: 0;
  align-items: center;
  column-gap: 3.125rem;
}

svg {
  display: inline-block;
  vertical-align: top;
}

h1 {
  font-weight: 900;
  font-size: 1.25rem;
  line-height: 1;
  margin: 0.375rem 0 0.375rem 0.625rem;
  display: inline-block;
  vertical-align: top;
}

button + button {
  margin-left: 0.625rem;
}

.header__middle-container {
  flex-grow: 1;
  height: auto;
}

header {
  position: sticky;
}
