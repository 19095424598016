.serverDomain {
  padding: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: var(--ohh_my_eyes-blue);
  color: white;
  font-weight: 900;
  font-size: 1.4rem;
  text-transform: uppercase;
  text-align: center;
  min-width: max-content;
  max-width: max-content;
}

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  justify-content: center;
}
