:root {
  --main__color: var(--dark-grey-color);
  --main__background: white;
  --main__separator-line: var(--separator-line_2);
  --aside__separator-line: var(--separator-line_1);
}

.main {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  color: var(--main__color);
  background-color: var(--main__background);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.body {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.header--right {
  display: flex;
  column-gap: 1rem;
}

.header--left {
  font-weight: 700;
  text-transform: uppercase;

  & > .header__submenu {
    color: var(--gray_2--color);
  }
}

.nav {
  height: 100%;
  min-width: 16.438rem;
  width: 263px;
  display: grid;
  grid-template-columns: 1fr 1.5rem;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    'top    control'
    'bottom control';
  flex-direction: column;
  align-items: flex-start;
  border-right: var(--main__separator-line);
  justify-content: space-between;
  padding: 0.625rem 0.625rem 0;
  overflow-y: auto;
  overflow-x: hidden;

  &.nav--colapsed {
    min-width: 5.625rem;
    max-width: 5.625rem;
    width: 5.625rem;

    & > .nav__top {
      align-items: center;
    }
    & > .nav__control {
      margin-left: -1.25rem;
    }
  }

  & > .nav__control {
    grid-area: control;
    display: flex;
    align-self: center;

    & > .control__icon {
      rotate: 90deg;
      cursor: pointer;

      &.control__icon--colapsed {
        rotate: 270deg;
      }
    }
  }

  & > .nav__top {
    grid-area: top;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    align-self: flex-start;
    width: fit-content;
  }

  & > .nav__bottom {
    grid-area: bottom;
    border-top: var(--aside__separator-line);
    align-self: flex-end;
    margin-right: 0;
    width: 100%;
  }

  .logo {
    // Workaround for some bug with pointer-events & navlink
    z-index: 1;
    & > svg {
      z-index: -1;
    }
  }
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
