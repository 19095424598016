@import "./inputVariables";

@mixin dateInput {
  @include input;
}

@mixin pickerIcon {
  color: var(--text-color);
}

@mixin dateInputError {
  @include inputError;
}