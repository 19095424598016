@import '../styles/pageTitleVariables';

.pageTitleContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;

  @include pageTitleContainer;

  padding: 33.5px 60px;
  border-bottom: 1px solid var(--light-gray-color);
  margin-bottom: 0;
  height: var(--page-title-height);
}

.sectionTitle {
  font-size: 30px;
  width: 100%;

  @include sectionTitle;
}

.rightSection {
  flex: 1;
  max-width: 50%;
  display: flex;
  justify-content: flex-end;

  @include media-breakpoint-only(xs) {
    flex: 0;
    max-width: initial;
  }

  @include rightSection;
}