.error__container {
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.error__status {
  font-size: 240px;
}

.error__description {

}
